.question-text {
  /* font-weight: 600;
  font-size: 30px; */

  letter-spacing: 2px;
  font-weight: 300;
}

.question-number {
  font-weight: 600;
  font-size: 16px;
  line-height: 50px;
  letter-spacing: 2px;
  font-weight: 300;
  opacity: 0.8;
}
.question-number span {
  color: var(--color-primary);
}

.progress {
  border-radius: 0;
}
.progress-bar {
  background-color: var(--color-primary);
  color: #fff;
}
