.checkbox-wrapper {
  position: relative;
}

.checkbox-wrapper label {
  cursor: pointer;
}

.checkbox-wrapper label:before,
.checkbox-wrapper label:after {
  content: "";
  position: absolute;
  top: 100%;
}

.checkbox-wrapper label:before {
  left: 0;
  width: 25px;
  height: 25px;
  margin: -25px 0 0;
  /* background: white; */
  border: 3px solid white;
}

.checkbox-wrapper label:after {
  left: 5px;
  width: 20px;
  height: 20px;
  margin: -22px -3px 0;
  opacity: 0;
  background: white;
}

.checkbox-wrapper input[type="checkbox"] {
  visibility: hidden;
}

.checkbox-wrapper input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
