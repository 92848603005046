input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(200, 0, 200);
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
}
.input-root input {
  padding: 4px 0px 5px;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  color: rgb(200, 0, 200);
}

.input-root input::placeholder {
  /* color: rgba(200, 0, 200, 0.5); */
  font-weight: 200;
  font-size: 1.3rem;
}

.root {
  margin: 8px;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: top;
}

.input-label {
  color: rgba(255, 255, 255, 0.7) !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(0px, 20px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-root {
  margin-top: 16px;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  /* color: rgb(255, 255, 255); */
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.input-root::before {
  border-bottom: 1px solid rgba(200, 0, 200, 0.5);
  left: 0px;
  bottom: 0px;
  content: " ";
  position: absolute;
  right: 0px;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.input-root::after {
  border-bottom: 2px solid var(--color-primary);
  left: 0px;
  bottom: 0px;
  content: "";
  position: absolute;
  right: 0px;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.input-label-focused,
.input-label-filled {
  /* color: red; */
  transform: translate(0px, -1.5px) scale(0.75);
}
.input-root-focused::after,
.input-root-filled::after {
  transform: scaleX(1);
}
input:focus {
  outline: 0px;
}
.input-root-focused::before {
  border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.input-root-error::after {
  border-bottom: 2px solid red;
}
