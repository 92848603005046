.btn-epicode-option-button,
.btn-epicode-option-button-selected {
  color: white;
  background: linear-gradient(
      180deg,
      rgba(132, 132, 132, 0.28) 0%,
      rgba(0, 0, 0, 0) 11.98%
    ),
    rgba(255, 255, 255, 0.1);

  /* width: 100%; */

  position: relative;
  border-radius: 100px;
  border: none;
}

.btn-epicode-option-button:before,
.btn-epicode-option-button-selected:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}

.btn-epicode-option-button:hover,
.btn-epicode-option-button-selected {
  background: linear-gradient(180deg, #d20094 0%, rgba(157, 29, 143, 0.6) 100%);
  color: white;
}
.btn-epicode-option-button-selected:hover {
  color: white !important;
}

.btn-epicode-option-button:hover::before,
.btn-epicode-option-button-selected:before {
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(210, 0, 148, 1) 100%
    )
    border-box;
}

.blink-1 {
  -webkit-animation: blink-1 0.6s both;
  animation: blink-1 0.6s both;
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
