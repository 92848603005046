.question-wrapper {
  border: 2px solid #e2e7ed;
  border-radius: 4px;
}
.grow {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 26px;
  overflow: hidden;
}

.qst-number {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

.qst-tag {
  border: 1px solid #000000;
  font-weight: 600;
  font-size: 8px;

  border-radius: 100px;
}

.qst-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
}

.text-ellipsis {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topics-container {
  height: 100%;
}
.qst_image {
  width: 100%;
}
@media (min-width: 992px) {
  .topics-container {
    height: auto;
  }
  .qst_image {
    width: 60%;
  }
}
