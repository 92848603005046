:root {
  --color-primary: #9d1d8f;
  --color-secondary: #00ffff;
  --background-color: #fafbfd;
}

.main-layout {
  font-family: "Outfit", sans-serif;
  background-image: url("./assets/bg.svg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: white;
  font-size: 1.15rem;
  line-height: 1.4;
  letter-spacing: 0.05em;
  font-weight: 300;
}

/* .certificate-container {
  background-image: url("./assets/course_coverimage.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 90%;
  height: calc(100vh - 66px);
} */

.color-primary {
  color: var(--color-primary);
}

.logo {
  height: 40px;
}

.text-bold {
  font-weight: 700 !important;
}

.heading {
  font-weight: 400 !important;
  font-size: 80px;
  line-height: 60px;
  opacity: 0.9;
}
.heading span {
  font-weight: 700 !important;
  display: block;
}

.heading-2 {
  line-height: 50px;
  font-size: 50px;
  font-weight: 400;
}

.heading-3 {
  line-height: 33px;
  font-size: 26px;
  font-weight: 400;
}

font-size-base {
  font-size: 16px;
  line-height: 19.2px;
}

.font-size-6 {
  font-size: 14px;
  line-height: 16.8px;
}

.line-height-1 {
  line-height: 25.6px !important;
}

a {
  color: var(--color-primary);
}

ul {
  margin-left: 0;
}

.btn-epicode {
  background-color: var(--color-secondary);
  color: #0b113b;
  border-radius: 0;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  padding: 15px 50px;
}
.btn-epicode:active {
  box-shadow: none;
}

.btn-epicode-selected {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 100px;
}

.btn-epicode:hover {
  color: #0b113b;
  box-shadow: 0px 0px 30px var(--color-secondary);
  background-color: var(--color-secondary);
}

.width-50 {
  width: 50%;
}
.width-70 {
  width: 70%;
}
.chart-container {
  width: 35%;
}

.star-container {
  font-size: 4rem;
}

@media (max-width: 990px) {
  .width-50,
  .width-70 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 3.5rem;
  }

  .star-container {
    font-size: 3rem;
  }
  .heading-3 {
    font-size: 20px;
  }
  .width-50,
  .width-70 {
    width: 100%;
  }
}

@media (max-width: 537px) {
  .star-container {
    font-size: 2rem;
  }
  .heading {
    font-size: 60px;
  }
  .heading-2 {
    font-size: 40px;
  }
  /* .heading-3 {
    font-size: 15px;
    line-height: 19px;
  } */
}
@media (max-width: 1200) {
  .chart-container {
    width: 100%;
  }
}

.blink-1 {
  -webkit-animation: blink-1 0.6s both;
  animation: blink-1 0.6s both;
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.sentry-error-embed-wrapper {
  z-index: 1056 !important;
}
