.time-wrapper span {
  line-height: 1;
}
.time-wrapper span:not(:nth-child(2)) {
  font-size: 8px;
  text-transform: uppercase;
}
.time-wrapper span:first-child {
  font-weight: 600;
}
.time-wrapper span:nth-child(2) {
  font-size: 2.5rem;
  font-weight: 700;
}
