.certificate-bg-container {
  background-image: url("../../assets/course_coverimage.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 80%;
  height: 50vh;
}

.certificate-container {
  width: 75%;
  /* height: 80%; */
}

.certificate-nav {
  background-color: rgba(11, 17, 59, 0.8);
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* opacity: 0.8; */
  z-index: 10;
}

.certificate-container span {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

.icons-container img {
  width: 40px;
  height: 40px;
}

.footer span {
  font-size: 12px !important;
  color: white;
  line-height: 16.8px;
}
.certificate-description {
  width: 60%;
}
.share-container {
  position: relative;
}
.share-container::before {
  z-index: -10;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 116px;
  border-radius: 10px;
  border: 2px solid transparent; /*2*/
  background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 1) 0%,
      rgba(210, 0, 148, 1) 100%
    )
    border-box;
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
@media (max-width: 576px) {
  .certificate-container {
    width: 100%;
  }
  .certificate-description {
    width: 100%;
  }
  .certificate-bg-container {
    height: 50vh;
    background-size: 100% 100%;
  }
}

@media (min-width: 100vh) {
  .certificate-bg-container {
    height: 100vh;
    background-size: 100% 80%;
  }
}
/* 945  1366*/
@media (min-height: 790px) and (orientation: landscape) {
  .certificate-bg-container {
    height: 100vh;
    background-size: 100% 80%;
  }
}
